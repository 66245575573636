import React from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import fvBg01 from "../images/fvBg01.svg"
import fvBg02 from "../images/fvBg02.svg"
import fvBg03 from "../images/fvBg03.svg"
import fvBgSP03 from "../images/fvBgSP03.svg"
import { fvContainer, fvInner, fvBgImage01, fvBgImage02, fvBgImage03, fvBgImageSP03 } from "../styles/common"
import parse from "html-react-parser"
import "../styles/wp-style.css"
import "../styles/wp-theme.css"

const UtilityPage = ({ data }) => {
  const { homepage } = data.site
  const content = data.wpPage

  return (
    <Layout {...homepage}>
      <Seo title={content.title} />
      <div css={[fvContainer, utilityFv]}>
        <img src={fvBg01} alt="bg shapes" css={fvBgImage01} />
        <img src={fvBg02} alt="bg shapes" css={fvBgImage02} />
        <img src={fvBg03} alt="bg shapes" css={fvBgImage03} />
        <img src={fvBgSP03} alt="bg shapes" css={fvBgImageSP03} />
        <div css={fvInner}>
          <h2 css={styledHeadline}>{content.title}</h2>
        </div>
      </div>
      <section css={styledContent}>{parse(content.content)}</section>
      <div css={utilityContact}>
        <Link to="/#contact" css={contactLink}>
          お問い合わせ
        </Link>
      </div>
    </Layout>
  )
}
export default UtilityPage

const utilityFv = css`
  height: 18vw;

  @media (max-width: 767px) {
    height: 45vw;
  }
`
const styledHeadline = css`
  font-size: 40px;

  @media (max-width: 767px) {
    font-size: 22px;
  }
`
const styledContent = css`
  width: clamp(748px, 80%, 2000px);
  margin: 0 auto;
  padding: 90px 0;

  @media (max-width: 767px) {
    width: 94%;
    padding: 10px 0 40px;
  }

  h3 {
    font-size: 30px;
    margin-bottom: 0.5em;

    &:not(:first-of-type) {
      margin-top: 1.5em;
    }

    @media (max-width: 767px) {
      font-size: 17px;
      margin-top: 1.5em;
    }
  }
  p,li {
    font-weight: normal;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .utility-p {
    margin-bottom: 1.5em;
    @media (max-width: 767px) {
      margin-top: 1.5em;
    }
  }
`
const utilityContact = css`
  background: #f0f2f4;
  padding: 90px 0;

  @media (max-width: 767px) {
    padding: 40px 0;
  }

  p {
    font-weight: normal;
    width: 40%;
    text-align: center;
    margin: 0 auto 50px;

    @media (max-width: 767px) {
      width: 94%;
      margin-bottom: 26px;
    }
  }
`
const contactLink = css`
  margin: 0 auto;
  width: 400px;
  height: 90px;
  background: #003c90;
  color: white;
  border-radius: 45px;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 24px;

  @media (max-width: 767px) {
    width: clamp(200px, 80%, 262px);
    height: 63px;
    font-size: 16px;
  }
`

export const query = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    wpPage(id: { eq: $id }) {
      title
      slug
      content
    }
  }
`
